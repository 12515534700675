[data-component='container-isi-v2'] .aaaem-isi-container {
  &__isi-content {
    >div:first-child {
      padding-top: 30px;
      padding-left: 24px;
      padding-right: 24px;

      @include mq('medium') {
        padding-top: 28px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

[data-component='container-isi-v2'] .isi-container {
  border-top: 0;
}

[data-component=container-isi-v2] .aaaem-isi-banner {
  button {
    margin-left: auto;
  }
}

.aaaem-isi {
  &-holder {
    margin-top: 2px;
    margin-bottom: var(--emu-common-spacing-xl);

    @include mq('large') {
      margin-top: var(--emu-common-spacing-xl);
      margin-bottom: 96px;
    }

    p {
      font-size: var(--emu-component-containers-isi-body-font-sizes-narrow-xxl);
      line-height: normal;
      margin: var(--emu-common-spacing-none);
      padding-right: 40px;
      color: var(--emu-common-colors-teal-500);

      @include mq('large') {
        font-size: var(--emu-component-containers-isi-body-font-sizes-wide-medium);
        padding-right: var(--emu-common-spacing-none);
        padding-left: 65px;
      }
    }

    sup {
      font-size: var(--emu-component-containers-isi-body-font-sizes-narrow-xl);
    }
  }

  &-banner {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 11px;
    padding-bottom: 11px;
    background: var(--emu-semantic-colors-primary-50);
    display: grid !important;
    grid-auto-flow: column;
    width: 100%;
    justify-content: normal !important;

    button {
      padding: var(--emu-common-spacing-none);
      display: grid !important;
      grid-template-areas: 'label icon';
      grid-gap: 3px;
    }
  }

  &-container {
    position: relative;

    &__banner-content {
      // To override the ISI component's logic of hard coding the banner ttle to "display: none" when expanded
      display: inline-block !important;

      p {
        color: var(--emu-common-colors-white);
        font-family: Gotham, sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 140%;
        padding-right: var(--emu-common-spacing-none);

        @include mq('medium') {
          font-size: 14px;
          font-family: GothamBold, sans-serif;
        }
      }
    }

    &__button-wrapper {
      position: absolute;
      top: 12px;
      right: 16px;
      width: 100%;
      cursor: pointer;

      @include mq('medium') {
        top: 14px;
      }
      @include mq('large') {
        padding-right: 50px;
      }

      .plus-minus-icon {
        .aaaem-button__icon {
          height: 8px;
          width: 8px;
          grid-area: icon;
          padding-top: 2px;

          @include mq('medium') {
            padding-top: var(--emu-common-spacing-none);
          }
        }
      }

      .cmp-button__text {
        grid-area: label;
        color: var(--emu-common-colors-dark-grey-1000);
        font-family: Gotham, sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 140%;
        text-transform: uppercase;

        @include mq('medium') {
          font-size: 12px;
          font-family: GothamBold, sans-serif;
        }
      }
    }

    &.isi-container {
    max-width: 1440px; // As per design.
      margin-left: auto;
      margin-right: auto;
      left: unset;

      .plus-minus-icon {
        .aaaem-button__icon {
          &::before,
          &::after {
            background-color: var(--emu-common-colors-dark-grey-1000);
          }
        }
        &:active {
          outline: revert;
        }

        &:focus {
          .aaaem-button__icon {

            &::before,
            &::after {
              background-color: var(--emu-common-colors-dark-grey-1000);
            }
          }
        }
      }
    }

    &__isi-content {
      @include mq('large') {
        margin-left: 66px;
        margin-right: 66px;
      }

      .bold {
        padding-bottom: 14px;
      }

      .aaaem-text {
        p {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      h5 {
        font-family: Arial, sans-serif;
        line-height: var(--emu-common-line-heights-wide-large);
        color: var(--emu-common-colors-teal-500);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }

      p,
      li {
        font-size: var(--emu-component-containers-isi-body-font-sizes-wide-medium);
        color: var(--emu-common-colors-dark-grey-500);
        line-height: var(--emu-common-line-heights-wide-medium);
      }

      b {
        font-family: Arial, sans-serif;
      }

      p {
        margin-bottom: 14px;
        margin-top: var(--emu-common-spacing-none);
        padding: var(--emu-common-spacing-none);

        a {
          color: var(--emu-common-colors-teal-500);
        }

        a[href='#tel'] {
          color: var(--emu-common-colors-dark-grey-500);
          text-decoration: none;
        }
      }

      ul {
        margin-top: var(--emu-common-spacing-none);
        padding-left: 22px; // to match figma
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }

      h5+p {
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
      }

      .natrelle__isi {
        &-title {
          margin-bottom: var(--emu-common-spacing-large);
          margin-bottom: 15px;

          h5 {
            margin: var(--emu-common-spacing-none);
          }

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &-text {
          margin-top: var(--emu-common-spacing-medium);

          p {
            margin-bottom: var(--emu-common-spacing-large);

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }
  }
}


#error-page {
  .root,
  [data-component='container-isi-v2'] .aaaem-isi-container__isi-content {
    background-color: var(--emu-common-colors-white);
  }
}
