#error-page {
  .error-page-content {
    margin-top: 140px;
    margin-bottom: 244px;
    max-width: 800px;
    h2, 
    h2 p {
      color: var(--emu-common-colors-teal-550);
      font-family: var(--emu-semantic-font-families-heading);
      font-size: 96px;
      line-height:  89px;
      &:not(:first-of-type) {
        margin-top: 0;
      }
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
      @include mq('large') {
        display: block;
        font-size: 124px;
        line-height: 111px;
        margin: auto;
      }
    }
    .aaaem-text {
      p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
        padding-left: 20px;
        padding-right: 20px;
        @include mq('large') {
          margin-bottom: 64px;
          margin-top: 64px;
          padding-left: 126px;
          padding-right: 126px;
        }
        a {
          color: inherit;
        }
      }
    }
    .emu-button {
      span,
      .cmp-button__text {
          font-family: var(--emu-semantic-font-families-body);
          font-size: 12px;
          line-height: 13px;
      }
      &.emu-button__primary-filled {
          border-radius: 0;
          color: var(--emu-common-colors-white);
          padding: 16px 32px;
          &:hover,
          &:active,
          &:focus {
              border-color: var(--emu-semantic-colors-secondary-200);
              color: var(--emu-common-colors-white);
          }
          &:hover {
              background-color: rgba(0, 48, 92, 0.5);
          }
          &:active {
              background-color: rgba(0, 48, 92, 0.8);
          }
      }
    }
  }
}
