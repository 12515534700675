.success-modal {
    .modal-content {
        background-color: var(--emu-semantic-colors-primary-50);
        padding-bottom: 72px;
        padding-top: 72px;
        @include mq('medium') {
            padding-bottom: 110px;
            padding-top: 110px;
        }
        h2 {
            display: inline-block;
            font-size: 27px;
            line-height: 35px;
            margin-right: 8px;
            @include mq('medium') {
                font-size: 46px;
                line-height: 86px;
                margin-right: 12px;
            }
            @include mq(800px) {
                font-size: 66px;
                line-height: 86px;
                margin-right: 16px;
            }
        }
        p, h2 {
            color: var(--emu-common-colors-white);
            margin-bottom: 0;
            margin-top: 0;
            &.img {
                display: inline-block;
            }
        }
    }
    .title-section {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .sub-text {
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
        max-width: 620px;
    }
    svg {
        path {
            fill: var(--emu-common-colors-white);
        }
        height: 28px;
        @include mq('medium') {
          height: 46px;
        }
        @include mq(800px) {
            height: 69px;
        }
    }
}