header {
  &.experiencefragment {
    left: 0;
    top: 0;
    width: 100%;
    z-index: var(--emu-common-other-z-index-header);
    .button {
      .nav__button {
        &.emu-button {
          position: relative;
          border: none;
          &:active {
            outline: none;
          }
        > span {
          left: 15px;
          position: absolute;
          top: 6px;
        }
        }
      }
    }
  }
}

@import './nav.scss';