form {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  padding-left: 24px;
  padding-right: 24px;

  @include mq('medium') {
    font-size: 16px;
    line-height: 19px;
    p {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .emu-form-textarea,
  .emu-form-text {
    margin-bottom: 8px;
    margin-top: 8px;
    position: relative;
    width: 100%;
    &__container,
    input {
      width: 100%;
      &::placeholder {
        color: var(--emu-component-ingredients-text-input-text-color-default-light);
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
         -webkit-appearance: none;
      }
    }
  }

  textarea {
    &::placeholder {
      color: var(--emu-component-ingredients-text-input-text-color-default-light) !important;
    }
  }

  // radio base styling
  input[type="radio"] {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
    border: 2px solid var(--emu-semantic-colors-primary-500);
    border-radius: 50%;
    appearance: none;
    background-color: transparent;
    outline: none;
  }

  .emu-form-radio {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
    @include mq('medium') {
      display: block;
    }
    &__label {
      display: inline-flex;
      margin-bottom: 0;
      @include mq('medium') {
        margin: var(--emu-component-ingredients-radio-input-label-margin-wide);
      }
      &:first-of-type {
        margin-top: 8px;
        @include mq('medium') {
          margin-left: 0;
        }
      }
    }
  }
  
  // radio checked state
  input[type="radio"]:not(:disabled):checked {
    border-color: var(--emu-semantic-colors-primary-500);
    background-color: var(--emu-semantic-colors-primary-500);
    background-clip: content-box;
    padding: 2px;
    background-image: radial-gradient(
      circle,
      var(--emu-semantic-colors-primary-500) 0%,
      var(--emu-semantic-colors-primary-500) 50%,
      transparent 60%,
      transparent 100%
    );
  }
  // radio disabled state
  input[type="radio"]:disabled {
    background-color: #d9d9d9;
  }
  
  input[type="radio"]:disabled:checked {
    background-image: radial-gradient(
      circle,
      #8b8c89 0%,
      #8b8c89 50%,
      transparent 50%,
      transparent 100%
    );
  }
  ::placeholder {
    color: var(--emu-semantic-colors-primary-500);
  }

  .emu-form-dropdown {
    margin: 0;
    position: relative;
    &__container {
      display: none;
    }
  }

  .emu-form-dropdown__select {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .emu-form-dropdown {
    .emu-dropdown-menu {
      &__group {
        border-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
        top: 58px !important;
        width: 100% !important;
        @include mq('medium') {
          top: 64px !important;
        }
        li {
          &:first-of-type {
            display: none;
          }
        }
      }
      &__button {
        border: solid 1px var(--emu-semantic-colors-secondary-500);
        justify-content: left;
        margin-bottom: 8px;
        margin-top: 8px;
        padding: 12px;
        width: 100% !important;
      }
    }
    &:not(.js-hide-dropdown) {
      &:not([data-multiple]) {
        .emu-dropdown-menu {
          &__group {
            background-color: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
            border: solid 1px var(--emu-semantic-colors-secondary-500);
            border-top-left-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
            border-top-right-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
            border-top-width: 1px;
            padding-bottom: 4px;
            padding-top: 4px;
          }
          &__button {
            border-bottom-left-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
            border-bottom-right-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
          }
        }
      }
    }
    &:not(.js-option-selected) {
      .emu-dropdown-menu {
        &__button {
          &:not(.js-option-selected) {
            span {
              &::after {
                content: ' *';
                color: var(--emu-semantic-colors-error-500);
              }
            }
          }
        }
      }
    }
    .emu-dropdown-menu {
      &__button {
        svg {
          fill: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
          margin-left: 8px;
        }
      }
    }
  }
  
  // turn label into placeholder 
  .social-input,  
  .emu-form-textarea[data-required="true"],
  .emu-form-text[data-required="true"] {
    label {
      position: absolute;
      left: 13px;
      top: 3px;
      z-index: 1;
      &.js-hidden {
        display: none;
      }
    }
  }

  .emu-form-textarea[data-required="true"],
  .emu-form-text[data-required="true"] {
    label {
      &::after {
        content: ' *';
        color: var(--emu-semantic-colors-error-500);
      }
    }
  }

  .social-input {
    &.instagram,
    &.tiktok,
    &.fb {
      label {
        top: -2px;
        &::before {
          background-position: top left;
          content: '';
          display: inline-block;
          height: 21px;
          margin-right: 8px;
          position: relative;
          top: 4px;
          width: 20px;
        }
      }
    }
    &.instagram {
      label {
        &::before {
          background-image: url('data:image/svg+xml,<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0202 0.461182H9.97464C4.4658 0.461182 0 4.92814 0 10.4384V10.484C0 15.9942 4.4658 20.4612 9.97464 20.4612H10.0202C15.529 20.4612 19.9948 15.9942 19.9948 10.484V10.4384C19.9948 4.92814 15.529 0.461182 10.0202 0.461182Z" fill="%2300305C"/><path d="M13.0914 4.53003H6.90447C5.19521 4.53003 3.80469 5.92091 3.80469 7.63061V13.2922C3.80469 15.0019 5.19521 16.3928 6.90447 16.3928H13.0914C14.8007 16.3928 16.1912 15.0019 16.1912 13.2922V7.63061C16.1912 5.92091 14.8007 4.53003 13.0914 4.53003ZM4.89819 7.63061C4.89819 6.52421 5.79835 5.62382 6.90447 5.62382H13.0914C14.1975 5.62382 15.0977 6.52421 15.0977 7.63061V13.2922C15.0977 14.3986 14.1975 15.299 13.0914 15.299H6.90447C5.79835 15.299 4.89819 14.3986 4.89819 13.2922V7.63061Z" fill="white"/><path d="M9.99758 13.3448C11.5871 13.3448 12.8809 12.0513 12.8809 10.4607C12.8809 8.87015 11.5878 7.57666 9.99758 7.57666C8.40741 7.57666 7.11426 8.87015 7.11426 10.4607C7.11426 12.0513 8.40741 13.3448 9.99758 13.3448ZM9.99758 8.67115C10.9846 8.67115 11.7874 9.47415 11.7874 10.4614C11.7874 11.4487 10.9846 12.2517 9.99758 12.2517C9.01056 12.2517 8.20776 11.4487 8.20776 10.4614C8.20776 9.47415 9.01056 8.67115 9.99758 8.67115Z" fill="white"/><path d="M13.148 8.04268C13.576 8.04268 13.9248 7.69443 13.9248 7.2656C13.9248 6.83677 13.5767 6.48853 13.148 6.48853C12.7192 6.48853 12.3711 6.83677 12.3711 7.2656C12.3711 7.69443 12.7192 8.04268 13.148 8.04268Z" fill="white"/></svg>');
        }
      }
    }
    &.tiktok {
      label {
        &::before {
          background-image: url('data:image/svg+xml,<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0202 0.461182H9.97464C4.4658 0.461182 0 4.92814 0 10.4384V10.484C0 15.9942 4.4658 20.4612 9.97464 20.4612H10.0202C15.529 20.4612 19.9948 15.9942 19.9948 10.484V10.4384C19.9948 4.92814 15.529 0.461182 10.0202 0.461182Z" fill="%2300305C"/><path d="M14.8307 7.69654V9.58492C14.5008 9.55269 14.072 9.47772 13.5978 9.30394C12.9785 9.07692 12.5176 8.76651 12.2157 8.52617V12.3429L12.208 12.331C12.2129 12.4066 12.2157 12.4837 12.2157 12.5615C12.2157 14.4569 10.6738 15.9998 8.77825 15.9998C6.88266 15.9998 5.34082 14.4569 5.34082 12.5615C5.34082 10.6661 6.88266 9.12246 8.77825 9.12246C8.96389 9.12246 9.14602 9.13718 9.32395 9.1659V11.027C9.15303 10.966 8.96949 10.9331 8.77825 10.9331C7.88089 10.9331 7.15025 11.6632 7.15025 12.5615C7.15025 13.4598 7.88089 14.1899 8.77825 14.1899C9.67561 14.1899 10.4062 13.4591 10.4062 12.5615C10.4062 12.5279 10.4055 12.4942 10.4034 12.4606V5.0437H12.2899C12.2969 5.20346 12.3032 5.36462 12.3103 5.52438C12.3229 5.839 12.4349 6.14099 12.6304 6.38834C12.8595 6.67913 13.1978 7.01687 13.6728 7.28663C14.1176 7.53818 14.5351 7.64679 14.8307 7.69794V7.69654Z" fill="white"/></svg>');
        }
      }
    }
    &.fb {
      label {
        &::before {
          background-image: url('data:image/svg+xml,<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.9934 10.4615C19.9934 15.5122 16.2507 19.6876 11.3886 20.3652C10.934 20.4282 10.4688 20.4612 9.99671 20.4612C9.45172 20.4612 8.91655 20.4177 8.39538 20.3337C3.63555 19.5671 0 15.4386 0 10.4615C0 4.93863 4.47614 0.461182 9.99741 0.461182C15.5187 0.461182 19.9948 4.93863 19.9948 10.4615H19.9934Z" fill="%2300305C"/><path d="M11.389 8.49116V10.6696H14.0831L13.6565 13.6042H11.389V20.3652C10.9344 20.4282 10.4692 20.4612 9.9971 20.4612C9.45211 20.4612 8.91694 20.4177 8.39577 20.3337V13.6042H5.91113V10.6696H8.39577V8.00418C8.39577 6.35054 9.73581 5.0094 11.3897 5.0094V5.0108C11.3946 5.0108 11.3988 5.0094 11.4037 5.0094H14.0838V7.54733H12.3325C11.8121 7.54733 11.3897 7.96985 11.3897 8.49047L11.389 8.49116Z" fill="white"/></svg>');
        }
      }
    }
  }

  h4 {
    margin-top: 32px;
    @include mq('medium') {
      margin-top: 40px;
    }
  }

  .required-title {
    h2, h3, h4, h5 {
      &::after {
        content: ' *';
        color: var(--emu-semantic-colors-error-500);
        display: inline-block;
      }
    }
  }
  
  .emu-form-button {
    &.emu-form-button__primary-filled {
      width: 100%;
      span {
        width: 100%;
      }
    }
  }
  .follow-us-tags {
    p {
      margin-bottom: 32px;
      margin-top: 8px;
      @include mq('medium') {
        margin-bottom:40px;
        margin-top: 8px;
      }
    }
  }
  .privacy-notice {
    margin-bottom: 32px;
    margin-top: 32px;
    @include mq('medium') {
      margin-bottom: 40px;
      margin-top: 40px;
    }
    p {
      font-size: 14px;
      line-height: 17px;
    }
    b {
      font-family: GothamBold, sans-serif;
    }
  }
  .collapse-text,
  .expand-text {
    cursor: pointer;
    &.js-hidden {
      display: none;
    }
  }
  .required {
    color: var(--emu-semantic-colors-error-500);
  }
  .expanded-content {
    &.js-hidden {
      display: none;
    }
  }
  .js-half-width--desktop {
    @include mq('medium') {
      width: calc(50% - 5px);
      &.text.aem-GridColumn.aem-GridColumn--default--12 {
        width: calc(50% - 5px);
      }
      &:nth-of-type(even) {
        margin-right: 10px;
      }
    }
  }
  .js-third-width--desktop {
    @include mq('medium') {
      margin-right: 10px;
      width: calc(33.33% - 6.67px);
      &.text.aem-GridColumn.aem-GridColumn--default--12,
      &.options.aem-GridColumn.aem-GridColumn--default--12 {
        width: calc(33.33% - 6.67px);
      }
      &:nth-of-type(3n + 2) {
        margin-right: 0;
      }
    }
  }
  .plastic-surgeon-el,
  .referral-surgeon-el,
  .referral-other-el,
  .procedure-date-el {
    &.js-hidden {
      display: none;
      @include aem-editor-view() {
        display: block;
      }
    }
  }

  input[type="month"] {
    &::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml,%3Csvg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.68708 5.45173H11.5M3.33489 1.11678V2.53099M3.33489 2.53099L11.5384 2.53083M3.33489 2.53099C1.97564 2.53099 0.873888 3.65182 0.873951 5.03458L0.874332 13.3799C0.874395 14.7626 1.97623 15.8835 3.33539 15.8835H11.5389C12.8982 15.8835 14 14.7625 14 13.3797L13.9996 5.03434C13.9995 3.65167 12.8976 2.53083 11.5384 2.53083M11.5384 1.1167V2.53083M5.79656 12.9626V7.9553L4.15585 9.20712M10.3085 12.9626V7.9553L8.66779 9.20712" stroke="%2300305C" stroke-width="1.54424" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
    }
  }

  input[type="month"] {
    &::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml,%3Csvg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.68708 5.45173H11.5M3.33489 1.11678V2.53099M3.33489 2.53099L11.5384 2.53083M3.33489 2.53099C1.97564 2.53099 0.873888 3.65182 0.873951 5.03458L0.874332 13.3799C0.874395 14.7626 1.97623 15.8835 3.33539 15.8835H11.5389C12.8982 15.8835 14 14.7625 14 13.3797L13.9996 5.03434C13.9995 3.65167 12.8976 2.53083 11.5384 2.53083M11.5384 1.1167V2.53083M5.79656 12.9626V7.9553L4.15585 9.20712M10.3085 12.9626V7.9553L8.66779 9.20712" stroke="%2300305C" stroke-width="1.54424" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
    }
  }
  button[type="submit"] {
    margin-bottom: 0;
    @include mq('medium') {
      margin-bottom: 64px;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}