.cookieconsentbanner {
  .emu-consent-banner {
    max-width: 1440px;
    z-index: var(--emu-component-containers-isi-z-index);
    padding-left: 16px;
    padding-right: 16px;

    @include mq('large') {
      padding-left: 80px;
      padding-right: 88px;
    }
  }
}
