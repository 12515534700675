.aaaem-text {
    p {
        margin-bottom: 24px;
        margin-top: 24px;
    }
    &.casting-call {
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        padding-left: 26px;
        padding-right: 26px;
        max-width: 1022px;

        @include mq('medium') {
            margin-top: 66px;
        }
    }
    &.champions-women {
        margin-left: auto;
        margin-right: auto;
        padding-left: 26px;
        padding-right: 26px;
        max-width: 1022px;
    }
    &.mandatory-fields {
        p {
            margin-bottom: 0;
            margin-top: 6px;
        }
    }
    &.natrelle-insta {
        p {
            margin-top: 5px;
            margin-bottom: 32px;
            @include mq('medium') {
                margin-bottom: 40px;
            }
        }
    }
}
