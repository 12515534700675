html {
  -webkit-text-size-adjust: 100%;
}

// Commenting out, makes fonts less bold 
// :root {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
// }

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
  &.js-scroll-disabled {
    overflow: hidden;
  }
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
} 

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

.root {
  background-color: var(--emu-semantic-colors-background-light);
}

.aaaem-container {
  margin: auto;
}

.emu-consent-banner {
  box-shadow: none;
}
