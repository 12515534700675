.card {
    &:first-of-type {
        .emu-card {
            margin-right: 8px;
        }
    }
    &:nth-of-type(2) {
        .emu-card {
            margin-left: 8px;
        }
    }
    .emu-card:not(.for_patients_dropdown) {
        margin-left: 16px;
        margin-right: 16px;
        .emu-card { 
            &__wrapper {
                border-radius: 32px;
                overflow: hidden;
            }
            &__title-wrapper {
                cursor: pointer;
                justify-content: center;
            }
            &__title {
                width: auto;
                h5 {
                    color: var(--emu-semantic-colors-primary-0);
                    margin-bottom: 16px;
                    margin-top: 16px;
                    text-align: center;
                }
            }
            &__toggle-cta {
                .emu-button {
                    border-radius: 50%;
                    border-width: 2px;
                    height: 18px;
                    line-height: 8px;
                    padding: 6px;
                    margin-bottom: auto;
                    margin-left: 8px;
                    margin-right: auto;
                    margin-top: auto;
                    width: 18px;
                }
                .aaaem-button__icon {
                    &::before,
                    &::after {
                        border-radius: 1px;
                    }
                }
            }
            &__body {
                .aaaem-text {
                    margin-bottom: 16px;
                    margin-left: 32px;
                    margin-right: 32px;
                    margin-top: 16px;
                    p {
                        font-size: 16px;
                        line-height: 19.2px;
                        &first-of-type {
                            margin-top: 0;
                        }
                        &last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        footer {
            margin: 0;
            padding: 0;
        }
    }
}