.toggle-boxes-container {
    max-width: 700px;
    > div {
        display: flex;
        flex-direction: column;
        @include mq('medium') {
            flex-direction: row;
        }
    }
    .aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--6,
    .card {
        width: 100%;
        @include mq('medium') {
            width: 50%;
        }
        &:first-of-type {
            margin-bottom: 16px;
            @include mq('medium') {
                margin-bottom: 0;
            }
        }
        &:nth-of-type(2) {
            margin-bottom: 6px;
            @include mq('medium') {
                margin-bottom: 0;
            }
        }
    }
    .aaaem-card__content-wrapper {
        @include slide-open(is-open);
        display: block;
    }
}

.carousel-disclaimer {
    margin-bottom: 5.5px;
    margin-top: 70px;
    @include mq('medium') {
        margin-bottom: 24px;
        margin-top: 0;
    }
    p {
        b {
            font-family: var(--emu-common-font-families-serif);
            font-size: 24;
        }
    }
}

.hero-links-container {
    display: flex;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 14px;
    position: absolute;
    margin-top: 16px;
    top: 101px;
    width: 100%;
    z-index: var(--emu-common-other-z-index-layer);
    @include mq('large') {
        margin-top: 32px;
        padding-left: 32px;
        padding-right: 32px;
        top: 117px;
    }
    img {
        height: 32px;
    }
    svg {
        height: 32px;
        width: 32px;
        @include mq('large') {
            height: 48px;
            width: 48px;
        }
    }
    .natrelle-logo {
        &--mobile {
            @include mq('large') {
                display: none;
            }
        }
    }
    .social-links-container {
        display: flex;
        .image:not(:first-of-type) {
            margin-left: 10px;
        }
    }
}