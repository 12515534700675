// Animation Variables
$standardAnimationDuration: 1s;
$standardAnimationDelay: 0.2s;
$standardEase: ease;

// ****************************************************************************
// Animation Mixins

// Example Usage: @include fade-animation(0, 100, cardAnimation, 'out')
@mixin fade-animation(
  $startOpacity: 0,
  $endOpacity: 100%,
  $name: 'fade',
  $direction: ease
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;

  // Direction is to set whether you are fading something onto ("in") the page or off ("out") of the page
  @if $direction == 'in' {
    animation-timing-function: ease-out;
  } @else if $direction == 'out' {
    animation-timing-function: ease-in;
  } @else {
    animation-timing-function: $standardEase;
  }

  @keyframes #{$name} {
    from {
      opacity: $startOpacity;
    }
    to {
      opacity: $endOpacity;
    }
  }
}

// ****************************************************************************

// Example Usage: @include slide-animation(-25px, 0px, 0, 0, cardAnimation)
@mixin slide-animation(
  $startX: 0,
  $endX: 0,
  $startY: 0,
  $endY: 0,
  $name: slide
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: translateX($startX) translateY($startY);
    }
    to {
      transform: translateX($endX) translateY($endY);
    }
  }
}

// ****************************************************************************

// Example Usage: @include slide-open(is-open, 0.5s)
@mixin slide-open(
  $openClassName: is-open,
  $speed: 0.5s
) {
  max-height: 0;
  overflow: hidden;
  transition: max-height $speed ease-in-out;
  &.#{$openClassName} {
      max-height: 1000px;
      transition: max-height $speed ease-in-out;
  }
}

// ****************************************************************************

// Example Usage: @include fade-and-slide-animation(0, 100, -15px, 0px, 0, 0, cardAnimation, 'in')
@mixin fade-and-slide-animation(
  $startOpacity: 0,
  $endOpacity: 100%,
  $startX: 0,
  $endX: 0,
  $startY: 0,
  $endY: 0,
  $name: fadeAndSlide,
  $direction: 'ease'
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;

  // Direction is to set whether you are fading something onto ("in") the page or off ("out") of the page
  @if $direction == 'in' {
    animation-timing-function: ease-out;
  } @else if $direction == 'out' {
    animation-timing-function: ease-in;
  } @else {
    animation-timing-function: $standardEase;
  }

  @keyframes #{$name} {
    from {
      opacity: $startOpacity;
      transform: translateX($startX) translateY($startY);
    }
    to {
      opacity: $endOpacity;
      transform: translateX($endX) translateY($endY);
    }
  }
}

// ****************************************************************************

// Example Usage: @include rotate-animation(20deg, 90deg, rotateImage)
@mixin rotate-animation($start, $end, $name: rotate) {
  animation-name: $name;
  animation-duration: 0.25s;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: rotate($start);
    }
    to {
      transform: rotate($end);
    }
  }
}

// ****************************************************************************

// Example Usage: @include stagger-animation((.25s, .55s, .80s), '&', 'animation')
@mixin stagger-animation($delays, $selector, $delay-property: 'transition') {
  @for $i from 1 through length($delays) {
    $delay: nth($delays, $i);
    #{$selector}:nth-child(#{$i}) {
      #{$delay-property}-delay: $delay;
    }
  }
}