

.navigationlist {
    width: 100%;
    .emu-navigation {
      background-color: transparent;
      margin-left: 0;
      margin-right: 0;
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        @include mq('large') {
          flex-direction: row;
        }
        .nav-item {
          border: none;
          &:hover {
            background-color: transparent;
          }
          a {
            color: var(--emu-semantic-colors-primary-500);
          }
        }
        li {
          border-bottom: 1px solid var(--emu-common-colors-white);
          display: flex;
          justify-content: space-between;
          padding-bottom: 0;
          padding-top: 0;
          &:first-of-type {
            border-top: 1px solid var(--emu-common-colors-white);
          }
          @include mq('large') {
            border-bottom: none;
            display: inline;
            &:first-of-type {
              border-top: none;
            }
          }
          a {
            border: none;
            padding-bottom: 15px;
            padding-top: 15px;
            width: 100%;
            &:hover {
              background-color: transparent;
              opacity: 0.7;
            }
            @include mq('large') {
              padding-bottom: 30px;
              padding-left: 9px;
              padding-right: 9px;
              padding-top: 30px;
              width: auto;
            }
          }
          span {
            display: flex;
            justify-content: space-between;
            width: 100%;
            &::after {
              background-repeat: no-repeat;
              content: ' ';
              display: inline-block;
              height: 16px;
              margin-bottom: auto;
              margin-top: auto;
              width: 10px;
              @include mq('large') {
                display: none;
              }
            }
          }
        }
      }
    }
}
.navbar_container {
    background-color: var(--emu-common-colors-white);
    width: 100%;
    .navbar_inner-container {
        align-items: center;
        background-color: var(--emu-common-colors-white);
        display: flex;
        justify-content: space-between;
        max-width: 1156px;
        margin-left: auto;
        margin-right: auto;
        @include mq('large') {
            padding-left: 2%;
        }
        > .container {
        width: 100%;
        }
        img {
        height: 53px;
        }
        .natrelle-logo {
            &--desktop {
                display: none;
                @include mq('large') {
                    display: inline-block;
                }
                img {
                    height: 28px;
                }
            }
        }
    }
    .emu-navigation__item {
        font-family: GothamBook, sans-serif;
        font-size: 13px;
        line-height: 15px;
        @include mq('large') {
            font-family: GothamBold, sans-serif;
        }
    }
    #nav-modal {
        background-color: var(--emu-common-colors-white);
        bottom: 0;
        overflow: auto;
        padding-bottom: 120px;
        position: fixed;
        left: 0;
        right: 0;
        top: 84px;
        z-index: var(--emu-common-other-z-index-overlay);
        @include mq('large') {
            padding-bottom: 0;
            position: static;
        }
        &:not(.js-toggle-on) {
            display: none;
            @include mq('large') {
                display: block;
            }
        }
        .additional-links {
            background-color: transparent;
            text-align: center;
            @include mq('large') {
                display: none;
            }
            .button {
                margin-left: 12px;
                margin-right: 12px;
                padding-top: 30px;
                text-align: left;
                &:first-of-type {
                    padding-top: 15px;
                }
                a {
                    color: var(--emu-common-colors-dark-grey-500);
                    font-family: GothamBook, sans-serif;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
            a {
                &:hover {
                opacity: 0.7;
                }
            }
            a, 
            p {
                color: var(--emu-semantic-colors-primary-500);
                font-size: 14px;
                line-height: 22px;
                text-decoration: none;
            }
            p {
                margin-bottom: 30px;
                margin-top: 30px;
            }
        }
        .emu-navigation__content-wrapper {
            position: static;
        }
        .linkedin-link {
            align-content: center;
            display: inline-flex;
            flex-direction: row-reverse;
            svg {
                margin-bottom: auto;
                margin-left: 8px;
                margin-top: auto;
            }
        }
    }
    .button {
        @include mq('large') {
            display: none;
        }
    }
    .image {
        display: block;
        width: auto;
    }
    .aaaem-container {
        &.sub-nav {
            background-color: var(--emu-semantic-colors-secondary-400);
            display: block;
            font-family: GothamBold, sans-serif;
            main {
                display: flex;
                align-items: center;
            }
            .card {
                grid-row: 2 / span 1;
                &:first-of-type {
                    grid-column: 1 / span 2;
                    grid-row: 1;
                }
            }
            > div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                .container {
                    &:nth-of-type(2) {
                        display: none;
                        @include mq('large') {
                            display: inline;
                        }
                    }
                }
                @include mq('large') {
                    display: flex;
                    justify-content: center;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }
                > .button {
                    margin-left: auto; 
                    grid-row: 2 / span 1;
                    @include mq('large') {
                        display: none;
                    }
                }
                > .button,
                > .container {
                    width: fit-content;
                    @include mq('large') {
                        width: auto;
                    }
                }
                &::before,
                &::after {
                    display: none;
                }
            }
            .button,
            .emu-button,
            p,
            span {
                color: var(--emu-semantic-colors-secondary-800);
                font-family: inherit;
                font-size: 9.5px;
                line-height: 12px;
                margin-bottom: 0;
                margin-top: 0;
                text-decoration: none;
            }
            a {
                padding: 15px;
                &:hover {
                    color: var(--emu-semantic-colors-secondary-800);
                    p, span {
                        color: var(--emu-semantic-colors-secondary-800);
                    }
                    svg {
                    path {
                        fill: var(--emu-semantic-colors-secondary-800);
                    }
                    }
                }
            }

            p {
                // padding-bottom: 7px;
                // padding-top: 7px;
            }
            .button {
                align-items: center;
                display: inline-flex;
                // padding-bottom: 7px;
                // padding-top: 7px;
            }
            .button-group {
                display: none;
                margin-left: 0;
                @include mq('large') {
                    display: flex;
                }
            }
            .linkedin-button {
                align-items: center;
                display: flex;
                flex-direction: row-reverse;
                svg {
                    height: 22px;
                    margin-left: 8px;
                    width: 22px;
                }
            }
            .emu-button-v2 {
                &.all_brands,
                &.for_patients_dropdown {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                        color: inherit
                    }
                }
                &.all_brands {
                    color: var(--emu-semantic-colors-primary-500);
                    font-family: "GothamBold, sans-serif";
                    span {
                        color: inherit;
                    }
                }
                &.for_patients_dropdown {
                    h5 {
                        align-items: center;
                        display: flex;
                        &::before {
                            content: '';
                            background-image: url("../../assets/images/country-flags/us_flag.png");
                            display: inline-block;
                            height: 24px;
                            margin-right: 8px;
                            width: 24px;
                        }
                    }
                }
            }
            .nav {
                &__button {
                background-color: transparent !important;
                border: none;
                > span {
                    display: block;
                    &::before,
                    &::after {
                    content: '';
                    }
        
                    &::before,
                    .cmp-button__text,
                    &::after {
                        border-radius: 1px;
                        display: block;
                        width: 20px;
                        height: 2px;
                        background-color: var(--emu-semantic-colors-primary-500);
                        margin: 5px 0;
                        transition: 0.4s;
                    }
                    &::before {
                        margin-top: 2px;
                    }
                }
                &.js-toggle-on {
                    > span {
                    &::before {
                        transform: translate(-2, 6px) rotate(-45deg);
                        width: 25px;
                    }
                    .cmp-button__text {opacity: 0;}
                    &::after {
                        transform: translate(-2, -7px) rotate(45deg);
                        width: 25px;
                    }
                    }
                }
                }
            }
        }
    }
    .for_patients_dropdown,
    .all_brands { 
        .aaaem-card__content-wrapper {
            @include slide-open(is-open, 0.3s);
            display: block;
        }
        &.emu-card {
            margin: 0;
            .emu-card {
                &__wrapper {
                    background-color: transparent;
                    border-radius: 0;
                }
                &__title-wrapper {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    cursor: pointer;
                    padding: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                    }
                }
                &__content-wrapper {
                    color: var(--emu-semantic-colors-secondary-800);
                    background-color: #dddddd;
                    position: absolute;
                    z-index: var(--emu-common-other-z-index-modal);
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding-left: 0;
                    }
                    a {
                        color: inherit;
                        display: inline-block;
                        font-family: GothamBold, sans-serif;
                        font-size: 9.5px;
                        line-height: 12px;
                        padding: 10px 20px;
                        text-decoration: none;
                        text-wrap: nowrap;
                        width: 100%;
                        &:hover {
                            background-color: var(--emu-semantic-colors-secondary-700);
                        }
                    }
                }
                &__body {
                    .aaaem-text {
                        margin: 0;
                    }
                }
            }
        }
    }
    .all_brands {
        &.emu-card {
            .emu-card {
                &__title-wrapper {
                    h5 {
                        color: var(--emu-semantic-colors-primary-500);
                        font-family: GothamBold, sans-serif;
                        font-size: 9.5px;
                        line-height: 12px;
                        margin: 0;
                    }
                }
                &__toggle-cta {
                    display: none;
                }
            }
        }
    }
    .for_patients_dropdown {
        &.emu-card {
            .emu-card {
                &__title-wrapper {
                    padding-bottom: 9px;
                    padding-top: 9px;
                    h5 {
                        align-items: center;
                        color: var(--emu-semantic-colors-secondary-800);
                        display: flex;
                        font-family: GothamBold, sans-serif;
                        font-size: 9.5px;
                        line-height: 12px;
                        margin: 0;
                        &::before {
                            content: '';
                            background-image: url("../../assets/images/country-flags/us_flag.png");
                            display: inline-block;
                            height: 24px;
                            margin-right: 8px;
                            width: 24px;
                        }
                    }
                }
                &__toggle-cta {
                    margin-left: 8px;
                    .emu-button {
                        border: none;
                        padding: 0;
                        .chevron {
                            height: 12px;
                            svg path {
                                stroke: var(--emu-semantic-colors-secondary-800);
                                stroke-width: 2px;
                            }
                        }
                    }
                    
                }
                &__body {
                    .aaaem-container {
                        width: 100%;
                    }
                    .countries-dropdown-menu {
                        .countries {
                            align-items: center;
                            display: flex;
                            &::before {
                                content: "";
                                width: 24px;
                                height: 24px;
                                margin-right: 8px;
                                display: inline-block;
                            }
                        }
                        .canada {
                            &::before {
                                background-image: url("../../assets/images/country-flags/canada_flag.png");
                            }
                        }
                    }
                }
                &__content-wrapper {
                    width: 100%;
                }
            }
            footer {
                margin: 0;
                padding: 0;
            }
            .aaaem-button.caret-icon {
                .chevron{
                    svg {
                        transform: rotate(0deg) scale(0.75);
                    }
                }
                &.js-toggle-on {
                    .chevron{
                        svg {
                            transform: rotate(180deg) scale(0.75);
                        }
                    }
                }
            }
        }
    }
}
