.teaser {
  max-width: var(--emu-component-containers-container-max-width);
}
.cmp-teaser {
  overflow: hidden;
  

  .content-container {
    padding-top: 130px;

    .content {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
  }
}


.teaser-video-controls {
  display: none;
}

// hide video & show image on mobile
.emu-teaser.has-video.is-playing {
  .emu-teaser__video {
    display: none;
    @include mq('medium') {
      display: block;
    }
  }
  .cmp-teaser__image--mobile {
    position: relative;
    @include mq('medium') {
      position: absolute;
    }
  }
}

.emu-teaser {
  &__disclaimer {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    margin-left: 9px;
    margin-right: 9px;
    width: calc(100% - 18px);
    @include mq('medium') {
      display: block;
      font-size: 16px;
      line-height: normal;
      margin-left: 30px;
    }
    .mobile-view {
      font-size: 12px;
      line-height: 14px;
    }
  }
}