footer.experiencefragment {
  padding: 0;
}

footer {
  position: relative;
  background-color: var(--emu-common-colors-black);
  padding-top: 60px;
  padding-left: 16px;
  padding-right: 16px;

  @include mq('medium') {
    padding-right: 64px;
    padding-left: 64px;
  }
  @include mq('large') {
    padding-top: 50px;
    display: grid;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .upper_section {
    > div:nth-child(2) {
      border-bottom: 2px solid var(--emu-common-colors-white);

      @include mq('large') {
        padding-bottom: 75px;
      }
    }

    @include mq('large') {
      max-width: 1110px;
      padding-left: 50px;
      padding-right: 50px;

      .aem-Grid {
        display: flex;
      }

      .image.aem-GridColumn.aem-GridColumn--default--12 { 
        width: auto;
      }

      .container.responsivegrid.aem-GridColumn--default--12:nth-child(3) {
        width: auto;
      }
    } 

    .natrelle-image {
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 32px;

      @include mq('medium') {
        padding-right: 64px;
        padding-left: 64px;
      }
      @include mq('large') {
        width: 218px;
        padding: 0;
        margin-top: 44px;
      }
    }

    .footer-contents {
      display: flex;
      flex-direction: column;
      gap: 34px;

      @include mq('large') {
        flex-direction: row;
        gap: 24px;
        padding-left: 20px;
        padding-right: 2px;
      }

      .footer-content-text {
        display: flex;
        flex-direction: column;
        gap: 34px;

        @include mq('large') {
          gap: 25px;
        }
      }

      p {
        margin: 0;
        font-family: Arial, sans-serif;
        font-size: 20px;
        font-weight: var(--emu-semantic-font-weight-regular);
        line-height: 23px;
        display: flex;
        align-self: center;   
        
        @include mq('large') {
          align-self: auto;
          font-size: 15px;
          line-height: 18px;
          font-weight: var(--emu-semantic-font-weight-bold);
        }
      }

      a {
        color: var(--emu-common-colors-white);
        text-decoration-line: underline;
      }

      #one-trust {
        text-decoration: none;
        text-decoration-line: none;
      }

      img {
        padding-left: 4px;
      }
    }

    .footer-social-media {
      display: flex;
      gap: 16px;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 66px;

      @include mq('large') {
        gap: 5px;
        padding-top: 0;
        padding-bottom: 0;
        width: 75px;
      }

      img {
        width: 47px;
        height: 47px;

        @include mq('large') {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .lower_section {
    padding-top: 64px;
    padding-bottom: 64px;

    @include mq('large') {
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 1110px;
      padding-left: 50px;
      padding-right: 50px;

      .footer__copyright {
        display: flex;
        justify-content: space-between;
      }

      .copyright-text {
        margin-right: 144px;
      }
    }

    img {
      padding-right: 74px;
      padding-bottom: 35px;

      @include mq('medium') {
        padding-right: 225px;
      }
      @include mq('large') {
        width: 208px;
        padding: 0;
      }
    }

    p {
      color: var(--emu-common-colors-white);
      font-family: var(--emu-semantic-font-families-body);
      font-size: 20px;
      font-weight: var(--emu-semantic-font-weight-regular);
      line-height: normal;

      @include mq('large') {
        font-size: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    p:nth-child(3) {
      margin-bottom: 10px;

      @include mq('large') {
        margin-bottom: 5px;
      }
    }
    p:nth-child(4) {
      margin-top: 10px;

      @include mq('large') {
        margin-top: 5px;
      }
    }
  }
}

.emu-consent-banner.js-visible {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}